<template>
  <div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.form">
              <div :class="$style.logoContainer">
                <img v-lazy="$companyLogoPath" alt="">
              </div>
              <a-form class="login-form" :form="form" @submit="handleSubmit">
                <small>Pro změnu starého hesla zadejte nové heslo</small>
                <a-form-item
                  label="Heslo"
                  :validate-status="error('password') ? 'error' : ''"
                  :help="error('password') || ''">
                  <a-input
                    type="password"
                    v-decorator="['password', { rules: [{ validator: validateToNextPassword }, { required: form.getFieldValue('password_again') !== undefined && form.getFieldValue('password_again').length > 0, message: 'Nové heslo musí být vyplněné!' }, { min: 6, message: 'Nové heslo musí mít délku alespoň 6 znaků' }]}]"
                    placeholder="Nové heslo"
                    autocomplete="off"
                  />
                </a-form-item>
                <a-form-item
                  label="Potvrzení hesla"
                  :validate-status="error('password_again') ? 'error' : ''"
                  :help="error('password_again') || ''">
                  <a-input
                    type="password"
                    v-decorator="['password_again', { rules: [{ validator: compareToFirstPassword }, { required: form.getFieldValue('password') !== undefined && form.getFieldValue('password').length > 0, message: 'Nové heslo znovu adresa musí být vyplněné!' }, { min: 6, message: 'Nové heslo znovu musí mít délku alespoň 6 znaků' }]}]"
                    placeholder="Nové heslo znovu"
                    @blur="handleConfirmBlur"
                    autocomplete="off"
                  />
                </a-form-item>
                <div class="form-actions">
                  <a-button
                    type="primary"
                    html-type="submit"
                    class="login-form-button"
                    :block="true"
                    size="large"
                    :loading="loading"
                    :disabled="loading || hasErrors(form.getFieldsError())"
                  >Potvrdit</a-button>
                </div>
                <div class="text-center">
                  <router-link
                    class="text-primary utils__link--blue utils__link--underlined"
                    to="/auth/login"
                  >Už jsem si vzpomněl</router-link>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasErrors } from '../../../services/forms'

export default {
  data: function () {
    return {
      uerCodeRefresh: this.$route.params.code,
      loading: false,
      confirmDirty: false,
      form: this.$form.createForm(this),
      hasErrors,
    }
  },
  methods: {
    handleConfirmBlur(e) {
      this.confirmDirty = this.confirmDirty || !!e.target.value
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue('password')) {
        callback(new Error('Hesla se neshodují!'))
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      if (value && this.confirmDirty) {
        this.form.validateFields(['password_again'], { force: true })
      }
      callback()
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('auth/updatePass', { uer_code_refresh: this.uerCodeRefresh, password: this.form.getFieldValue('password') })
            .then(() => {
              this.$router.push('/auth/login')
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    init: function () {
      this.$store.dispatch('auth/codeExists', this.uerCodeRefresh)
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
